@font-face {
  font-family: 'Belfast Grotesk';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Belfast Grotesk Bold'), local('Belfast-Grotesk-Bold'), url('../public/belfast-grotesk-bold.woff2') format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
  font-family: 'Belfast Grotesk';
  font-style: oblique;
  font-display: swap;
  font-weight: 700;
  src: local('Belfast Grotesk Bold Oblique'), local('Belfast-Grotesk-Bold-Oblique'), url(../public/belfast-grotesk-bold-oblique.woff2) format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../public/montserrat-regular.woff2) format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../public/montserrat-italic.woff2) format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../public/montserrat-bold.woff2) format('woff2');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
